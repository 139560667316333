@import url(https://use.typekit.net/yns4wlf.css);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.a, .b, .c, .d, .e, .f, .g, .h, .i, .j, .k,
 .l, .m, .n, .o, .p, .q, .r, .s, .t, .u, .v, .w,
 .x, .y, .z {
  position: relative;
  z-index: 0;
}
.a {
  width: 50px;
}
.b {
  width: 60px;
}
.c {
  width: 70px;
}
.d {
  width: 80px;
}
.e {
  width: 90px;
}
.f {
  width: 100px;
}
.g {
  width: 110px;
}
.h {
  width: 120px;
}
.i {
  width: 130px;
}
.j {
  width: 140px;
}
.k {
  width: 150px;
}
.l {
  width: 160px;
}
.m {
  width: 170px;
}
.n{
  width: 180px;
}
.o {
  width: 190px;
}
.p {
  width: 200px;
}
.q{
  width: 210px;
}
.r {
  width: 220px;
}
.s {
  width: 230px;
}
.t {
  width: 240px;
}
.u{
  width: 250px;
}
.v {
  width: 150px;
}
.w{
  width: 160px;
}
.x{
  width: 170px;
}
.y{
  width: 180px;
}
.z{
  width: 140px;
}

#gator {
height: auto;
width: 100vw;
top: 0;
left: 0;
display: flex;
flex-direction: row;
flex-wrap: wrap;
}
.my-masonry-grid { /* Not needed if autoprefixing */ /* Not needed if autoprefixing */
  display: flex;
  margin-left: -1vw; /* gutter size offset */
  height: 75vh;
  width: 50vw;
  overflow: hidden
}
.my-masonry-grid_column {
  padding-left: 10px; /* gutter size */
  background-clip: padding-box;
}

#footer {
position: relative;
height: 50vh;
width: 100vw;
bottom: 0;
left: 0;
background: white;
z-index: 5;
box-shadow: -1px -1px 25px grey;
}

#text {
  font-family: ff-seria-sans, sans-serif;
  min-height: 10vh;
  height:-webkit-fit-content;
  height:-moz-fit-content;
  height:fit-content;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  background: black;
  font-size: 18px;
  color: wheat;
  position: absolute;
  top: 50vh;
  left: 50vw;
  z-index: 10;
}
@media screen and (max-width: 600px) {
  #text {
    font-size: 13px;
  }
}
html {
  width: 99vw;
  overflow-x: hidden;
  background-color: black;
}
.body {
  min-height: 100vh;
  max-height: 600vh;
  top: 0;
  left: 0;
  overflow-x: hidden;
  overflow-y: hidden;
  z-index: 0;
  background-position: center;
  background-repeat: no-repeat;
  background: black;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  scrollbar-color: dark;
}

.splashbody{
background-color: black;
height: 100vh;
width: 100vw;

}
#splashImg {
position: relative;
height: 400px;
top: 20vh;
left: 39vw;
}
.App {
  /* width: 100vw;
  height: 100 vh; */
  overflow-x: hidden;
  z-index: -1;
}


