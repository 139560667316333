@import url("https://use.typekit.net/yns4wlf.css");
#footer {
position: relative;
height: 50vh;
width: 100vw;
bottom: 0;
left: 0;
background: white;
z-index: 5;
box-shadow: -1px -1px 25px grey;
}

#text {
  font-family: ff-seria-sans, sans-serif;
  min-height: 10vh;
  height:fit-content;
  width: fit-content;
  background: black;
  font-size: 18px;
  color: wheat;
  position: absolute;
  top: 50vh;
  left: 50vw;
  z-index: 10;
}
@media screen and (max-width: 600px) {
  #text {
    font-size: 13px;
  }
}