#gator {
height: auto;
width: 100vw;
top: 0;
left: 0;
display: flex;
flex-direction: row;
flex-wrap: wrap;
}
.my-masonry-grid {
  display: -webkit-box; /* Not needed if autoprefixing */
  display: -ms-flexbox; /* Not needed if autoprefixing */
  display: flex;
  margin-left: -1vw; /* gutter size offset */
  height: 75vh;
  width: 50vw;
  overflow: hidden
}
.my-masonry-grid_column {
  padding-left: 10px; /* gutter size */
  background-clip: padding-box;
}
