.splashbody{
background-color: black;
height: 100vh;
width: 100vw;

}
#splashImg {
position: relative;
height: 400px;
top: 20vh;
left: 39vw;
}