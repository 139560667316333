html {
  width: 99vw;
  overflow-x: hidden;
  background-color: black;
}
.body {
  min-height: 100vh;
  max-height: 600vh;
  top: 0;
  left: 0;
  overflow-x: hidden;
  overflow-y: hidden;
  z-index: 0;
  background-position: center;
  background-repeat: no-repeat;
  background: black;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  scrollbar-color: dark;
}
